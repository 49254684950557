<template>
    <NotificationItem
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        v-on:remove-notification="removeNotification"
    >
    </NotificationItem>
</template>
<script setup lang="ts">
import NotificationItem from "./NotificationItem.vue";
import { useNotifications } from "@/composables/notifications";
const { notifications, removeNotification } = useNotifications();
</script>
